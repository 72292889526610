<template>
  <div
    id="app"
    class="min-h-screen bg-base-300 text-text-base"
  >
    <Navbar v-if="isAuthenticated" />
    <main
      v-if="isAuthenticated"
      class="container mx-auto p-4"
    >
      <router-view />
    </main>
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "@/components/NavbarComponent.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  async created() {
    await this.checkAuth();
  },
  methods: {
    ...mapActions("auth", ["checkAuth"]),
  },
};
</script>

<style>
html {
  overflow-y: scroll;
}

body {
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#app {
  color: var(--text-base);
}

/* You can add any other global styles here */
</style>
