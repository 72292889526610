const state = {
  summaryData: [
    { title: "TOTAL EQUITY", value: "$5,691.75", btcValue: "฿0.07867021" },
    { title: "INITIAL INVESTMENT", value: "$2,000", btcValue: "฿0.02333820" },
    {
      title: "TOTAL PROFIT (All time)",
      value: "$3,691.75",
      btcValue: "฿0.05533201",
    },
  ],
  chartType: "daily",
  chartData: {
    labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    datasets: [
      {
        label: "BTC",
        data: [4, 5, 8, 5, 4, 5, 1, 5, 7],
        borderColor: "#FF6384",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "USDT",
        data: [5, 4, 2, 3, 6, 7, 5, 3, 1],
        borderColor: "#FFCE56",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  },
  chartOptions: {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
      },
    },
  },
  performanceSummary: [
    { period: "TODAY", value: "$2.44", btcValue: "฿0.00380171" },
    { period: "YESTERDAY", value: "$3.30", btcValue: "฿0.00520188" },
    { period: "THIS WEEK", value: "$5.74", btcValue: "฿0.00907569" },
  ],
  tier: "Standard (60%)",
  cryptoInfo: [
    {
      name: "Bitcoin (BTC)",
      details: {
        Profit: "฿0.06042764",
        "Referral Bonus": "฿0.00608851",
        "QuantM Alpha Fee": "-฿0.02471056",
        "Balance Owing": "-฿0.018082546",
      },
    },
    {
      name: "Ethereum (ETH)",
      details: {
        Profit: "฿0.008287",
        "Referral Bonus": "฿0.000537",
        "QuantM Alpha Fee": "-฿0.0033148",
        "Balance Owing": "-฿0.0027778",
      },
    },
    {
      name: "USDT",
      details: {
        Profit: "$1,116.65",
        "Referral Bonus": "$669.99",
        "QuantM Alpha Fee": "-$424.33",
        "Balance Owing": "-$446.66",
      },
    },
  ],
};

const mutations = {
  SET_CHART_TYPE(state, type) {
    state.chartType = type;
  },
  SET_DASHBOARD_DATA(state, data) {
    Object.assign(state, data);
  },
};

const actions = {
  setChartType({ commit, dispatch }, type) {
    commit("SET_CHART_TYPE", type);
    dispatch("fetchChartData");
  },
  async fetchDashboardData({ commit }) {
    try {
      // In a real application, you would fetch data from an API here
      commit("SET_DASHBOARD_DATA", state);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  },
  async fetchChartData({ commit, state }) {
    try {
      // In a real application, you would fetch chart data based on the chartType
      // For now, we'll just use the same data
      commit("SET_DASHBOARD_DATA", { chartData: state.chartData });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
