import api from "@/api/axios";
import router from "@/router";

const state = {
  token: localStorage.getItem("token") || null,
  user: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
};

const actions = {
  async login({ commit }, credentials) {
    try {
      // First, try admin login
      const adminResponse = await api.post(
        "/api/auth/admin-login/",
        credentials
      );
      const token = adminResponse.data.token;
      localStorage.setItem("token", token);
      commit("setToken", token);
      commit("setUser", adminResponse.data);
      return true;
    } catch (adminError) {
      console.error("Admin login failed:", adminError);

      // If admin login fails, try regular login
      try {
        const userResponse = await api.post("/api/auth/login/", credentials);
        const token = userResponse.data.token;

        // User successfully logged in, but not as admin
        // Redirect to portal with the token
        const portalUrl =
          process.env.VUE_APP_PORTAL_URL || "http://localhost:3000";
        window.location.href = `${portalUrl}?token=${token}`;
        return false;
      } catch (userError) {
        console.error("User login failed:", userError);
        return false;
      }
    }
  },
  logout({ commit }) {
    localStorage.removeItem("token");
    commit("setToken", null);
    commit("setUser", null);
    router.push("/login");
  },
  async checkAuth({ commit, dispatch }) {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        // Make a request to validate the token
        const response = await api.get("/api/auth/admin-user/");
        commit("setToken", token);
        commit("setUser", response.data);
      } catch (error) {
        console.error("Token validation failed:", error);
        dispatch("logout");
      }
    } else {
      dispatch("logout");
    }
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
