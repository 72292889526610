<template>
  <div class="drawer">
    <input
      id="my-drawer-3"
      type="checkbox"
      class="drawer-toggle"
    >
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <div class="w-full navbar bg-base-200">
        <div class="flex-none lg:hidden">
          <label
            for="my-drawer-3"
            class="btn btn-square btn-ghost"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="inline-block w-6 h-6 stroke-current"
            ><path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            /></svg>
          </label>
        </div>
        <div class="flex-1 px-2 mx-2">
          <!-- display logo here -->
          <router-link to="/dashboard">
            <BrandComponent />
          </router-link>
        </div>
        <div class="flex-none hidden lg:block">
          <ul class="menu menu-horizontal">
            <li>
              <router-link to="/dashboard">
                Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/bots">
                Bots
              </router-link>
            </li>
            <li>
              <router-link to="/backtests">
                Backtests
              </router-link>
            </li>
            <li>
              <router-link to="/reports">
                Reports
              </router-link>
            </li>
            <li>
              <router-link to="/accounts">
                Accounts
              </router-link>
            </li>
            <li>
              <router-link to="/settings">
                Settings
              </router-link>
            </li>
            <li><a @click="handleLogout">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Sidebar / Drawer -->
    <div class="drawer-side">
      <label
        for="my-drawer-3"
        class="drawer-overlay"
      />
      <ul class="menu p-4 w-80 h-full bg-base-200">
        <li>
          <router-link to="/dashboard">
            Dashboard
          </router-link>
        </li>
        <li>
          <router-link to="/bots">
            Bots
          </router-link>
        </li>
        <li>
          <router-link to="/backtests">
            Backtests
          </router-link>
        </li>
        <li>
          <router-link to="/reports">
            Reports
          </router-link>
        </li>
        <li>
          <router-link to="/accounts">
            Accounts
          </router-link>
        </li>
        <li>
          <router-link to="/settings">
            Settings
          </router-link>
        </li>
        <li><a @click="handleLogout">Logout</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BrandComponent from '@/components/BrandComponent.vue';

export default {
  name: 'NavbarComponent',
  components: {
    BrandComponent
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async handleLogout() {
      await this.logout();
      this.$router.push('/login');
    }
  }
}
</script>
