import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/dashboard",
    component: () => import("../views/dashboard/DetailView.vue"),
    meta: { requiresAuth: true },
    children: [],
  },

  {
    path: "/backtests",
    component: () => import("../views/backtests/ListView.vue"),
    meta: { requiresAuth: true },
    children: [],
  },

  {
    path: "/bots",
    name: "Bots",
    component: () => import("../views/bots/ListView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/bots/:id",
    component: () => import("../views/bots/DetailView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "overview",
        name: "BotOverview",
        component: () => import("../views/bots/OverviewTab.vue"),
      },
      {
        path: "active-orders",
        name: "BotActiveOrders",
        component: () => import("../views/bots/ActiveOrders.vue"),
      },
      {
        path: "buys",
        name: "BotBuys",
        component: () => import("../views/bots/BuysTab.vue"),
      },
      {
        path: "sells",
        name: "BotSells",
        component: () => import("../views/bots/SellsTab.vue"),
      },
      {
        path: "cancelled",
        name: "BotCancelled",
        component: () => import("../views/bots/CancelledTab.vue"),
      },
      {
        path: "rejected",
        name: "BotRejected",
        component: () => import("../views/bots/RejectedTab.vue"),
      },
    ],
  },

  {
    path: "/reports",
    component: () => import("../views/reports/DetailView.vue"),
    meta: { requiresAuth: true },
    children: [],
  },

  {
    path: "/accounts",
    name: "Accounts",
    component: () => import("../views/accounts/ListView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/accounts/:id",
    component: () => import("../views/accounts/DetailView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "overview",
        name: "AccountOverview",
        component: () => import("../views/accounts/OverviewTab.vue"),
      },
      {
        path: "bots",
        name: "AccountBots",
        component: () => import("../views/accounts/BotsTab.vue"),
      },
      {
        path: "api-keys",
        name: "AccountAPIKeys",
        component: () => import("../views/accounts/APIKeysTab.vue"),
      },
      {
        path: "transactions",
        name: "AccountTransactions",
        component: () => import("../views/accounts/TransactionsTab.vue"),
      },
      {
        path: "portal-logins",
        name: "AccountPortalLogins",
        component: () => import("../views/accounts/PortalLoginsTab.vue"),
      },
    ],
  },
  {
    path: "/settings",
    component: () => import("../views/settings/DetailView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "strategies",
        name: "SettingsStrategies",
        component: () => import("../views/settings/StrategiesTab.vue"),
      },
      {
        path: "global-settings",
        name: "SettingsGlobal",
        component: () => import("../views/settings/GlobalSettingsTab.vue"),
      },
      {
        path: "reporting-periods",
        name: "SettingsReportingPeriods",
        component: () => import("../views/settings/ReportingPeriodsTab.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/isAuthenticated"]) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
